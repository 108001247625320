<template>
  <div>
    <ListingModal
      :currency="currency"
      :token-id="+id"
      v-show="showListingModal"
      @success="tokenOperationSuccess"
      @close="showListingModal = false"
    />

    <TransferModal
      v-if="showTransferModal"
      :token-id="+id"
      @close="showTransferModal = false"
      @success="tokenOperationSuccess"
    />

    <DeleteModal
      :state="'token'"
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @accept="burn"
    />

    <PageIntro
      :show-sort-filter="false"
      :title="tokenTitle"
      :minted="tokenCreated"
      :hash="tokenHash"
      :currency="currency"
      :show-tools="getId === ownerId"
      :edit-link="editNftLink(id, tokenCurrency, contract)"
      @deleteModal="showDeleteModal = true"
    />

    <div id="artwork" class="section">
      <div class="wrapper">
        <div class="artwork-info">
          <div class="intro">
            <div class="flex">
              <div class="creator">
                Creator:
                <UsernameLink
                  :username="creatorNickName"
                  :user-id="creatorId"
                  :user-address="creatorAddress"
                />
              </div>
              <div class="owner">
                Owner:
                <UsernameLink
                  :username="ownerNickName"
                  :user-id="ownerId"
                  :user-address="ownerAddress"
                />
              </div>
            </div>
            <div>Royalties: {{ tokenRoyalty }}%</div>
            <div><a :href="tokenFileUrl" target="_blank">Open original</a></div>

            <div class="origin-list">
              <template v-if="tokenCollections.length === 1"
                >Collection:
              </template>
              <template v-else>Collections: </template>
              <router-link
                v-for="(item, index) in tokenCollections"
                :key="index"
                :to="collectionLink(item.id)"
                >{{ item.title
                }}<template v-if="index !== tokenCollections.length - 1"
                  >,
                </template></router-link
              >
            </div>

            <div class="origin-list" v-if="tokenTags.length">
              Tags:
              <router-link
                v-for="(item, index) in tokenTags"
                :key="index"
                to=""
                @click.native="searchByTag(item)"
              >
                #{{ item }}
                <template v-if="index !== tokenTags.length - 1">, </template>
              </router-link>
            </div>
          </div>

          <ControlPanel
            v-if="mountControlPanel"
            :is-time-auction="isTimeAuction"
            :currency="currency"
            :token-meta-price="tokenMetaPrice"
            :token-id="+tokenId"
            :token-block-chain-id="tokenBlockChainId"
            :min-bid-price="minBidPrice"
            :is-sold="isSold"
            :end-time="endTime"
            :start-time="startTime"
            :owner-address="ownerAddress"
            :auction-id="auctionId"
            @success="tokenOperationSuccess"
            @sell="showListingModal = true"
            @transfer="showTransferModal = true"
            ref="controlPanel"
          />

          <Bids
            v-if="tokenBlockChainId"
            :block-chain-id="+id"
            :currency="tokenCurrency.toUpperCase()"
            :show-bids-tab="isTimeAuction"
            ref="Bids"
          />

          <div class="description">
            <h2>Description</h2>
            <p>{{ tokenDescription }}</p>
          </div>

          <div class="more" v-if="tokenCollectionId">
            <h2>More from collection</h2>
          </div>
        </div>

        <div class="artwork-subject">
          <TokenContentType
            :wrapper="'wrapper'"
            :file-url="tokenFileUrl"
            :token-type="tokenType"
            :preview-url="tokenPreviewUrl"
            :mute="false"
            :autoplay="false"
            :controls="true"
            :artwork-page="true"
          />
        </div>
      </div>
    </div>
    <!--[ #artwork ]-->

    <div id="more" class="tiles section" v-if="currentCollection">
      <div class="bg">
        <img :src="currentCollection.fileUrl" lazy="loaded" />
      </div>

      <div class="flex">
        <div class="info-tile">
          <div class="list" v-if="tokenCollections.length > 1">
            <div
              class="button round"
              v-for="(item, index) in tokenCollections"
              :key="index"
              :class="{
                active: currentCollection && item.id === currentCollection.id,
              }"
              @click="toggleCollectionTokens(item)"
            >
              {{ item.title }}
            </div>
          </div>

          <h2>{{ currentCollection.title }}</h2>

          <div>
            {{ total }} NFTs
            <router-link
              :to="
                collectionLink(currentCollection ? currentCollection.id : '')
              "
              >view all</router-link
            >
          </div>
          <div>
            Created by
            <UsernameLink
              :user-address="tokenCollectionOwnerAddress"
              :user-id="tokenCollectionOwnerId"
              :username="tokenCollectionOwnerNickname"
            />
          </div>
        </div>

        <TokenCard v-for="(item, index) in items" :key="index" :token="item" />
      </div>
    </div>
    <!--[ #more ]-->
  </div>
</template>

<script>
import PageIntro from "../components/common/PageIntro";
import TokenCard from "../components/common/TokenCard";
import api from "@/api/api";
import tokenInfoMixin from "@/mixins/tokenInfoMixin";
import UsernameLink from "@/components/common/UsernameLink";
import TokenContentType from "@/components/common/TokenContentType";
import ControlPanel from "@/components/artworkPage/ControlPanel";
import ListingModal from "@/components/modals/ListingModal";
import TransferModal from "@/components/modals/TransferModal";
import { mapGetters, mapMutations } from "vuex";
import Bids from "@/components/artworkPage/Bids";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import DeleteModal from "@/components/modals/DeleteModal";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import getTokenByBlockChainIdMixin from "../mixins/token/GetTokenByBlockChainIdMixin";
import getContractAddressByCurrencyMixin from "../mixins/getContractAddressByCurrencyMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "ArtworkPage",
  mixins: [
    setProcessingMixin,
    tokenInfoMixin,
    formatNumberMixin,
    getTokenByBlockChainIdMixin,
    getContractAddressByCurrencyMixin,
    useLinkMixin,
  ],
  props: {
    id: {
      type: String,
      required: false,
    },
    tokenCurrency: {
      type: String,
      required: true,
    },
    contract: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    mountControlPanel: true,
    showListingModal: false,
    showTransferModal: false,
    showEditTokenModal: false,
    showDeleteModal: false,
    items: [],
    total: 0,
    currentCollection: null,
  }),
  computed: {
    ...mapGetters({
      getAuction: "auction/getAuction",
      getId: "user/getId",
      getContract: "wallet/getContract",
    }),
  },
  watch: {
    async getAuction() {
      await this.init();
      this.$refs.controlPanel.initialize();
    },
    $route() {
      this.init();
    },
  },
  methods: {
    ...mapMutations({
      setSearch: "search/setSearch",
      setNeedRefresh: "general/setNeedRefresh",
    }),
    searchByTag(tag) {
      this.setSearch("");
      setTimeout(() => {
        this.setSearch(tag);
      }, 0);
    },
    async burn() {
      await this.$refs.controlPanel.burn();
    },
    async toggleCollectionTokens(collection) {
      this.currentCollection = collection;
      await this.getNftTokenByCollectionIdExcludingToken();
    },
    tokenOperationSuccess() {
      this.init();
      this.setNeedRefresh("Artworks");
    },
    async getNftTokenByCollectionIdExcludingToken() {
      try {
        const response = await api.getNftTokenByCollectionIdExcludingToken({
          collectionId: this.currentCollection.id,
          tokenId: this.tokenId,
          page: 0,
          size: 3,
        });
        this.items = response.content;
        this.total = response.totalElements + 1;
      } catch (e) {
        this.items = [];
      }
    },
    async init() {
      this.setLoading(true);
      this.mountControlPanel = false;
      if (this.contract) {
        await this.getNftTokenByBlockChainId();
      } else {
        await this.getNftTokenByInnerId();
      }
      if (this.tokenCollectionId) {
        this.currentCollection = this.tokenCollections[0];
        await this.getNftTokenByCollectionIdExcludingToken();
      }
      setTimeout(() => {
        this.mountControlPanel = true;
      }, 0);
      this.setLoading(false);
    },
  },
  async mounted() {
    await this.init();
  },
  components: {
    DeleteModal,
    Bids,
    TransferModal,
    ListingModal,
    ControlPanel,
    TokenContentType,
    UsernameLink,
    PageIntro,
    TokenCard,
  },
};
</script>
