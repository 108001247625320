<template>
  <div class="activity-item">
    <div class="activity-info">
      <div class="activity-desc">
        <template v-if="rateType === 'MINTED'">Minted</template>
        <template
          v-if="
            rateType === 'LISTED_FOR_SIMPLE_AUCTION' ||
            rateType === 'LISTED_FOR_TIME_AUCTION'
          "
        >
          Listed for
          <span>{{ formatNumber(ratePrice) }} {{ rateCurrency }}</span>
        </template>
        <template v-if="rateType === 'PURCHASE'">Purchase</template>
        <template v-if="rateType === 'CHANGE_PRICE'">
          Change price for
          <span>{{ formatNumber(ratePrice) }} {{ rateCurrency }}</span>
        </template>
        <template v-if="rateType === 'FLOOR_BID'">
          Floor bid for
          <span>{{ formatNumber(ratePrice) }} {{ rateCurrency }}</span>
        </template>
        <template v-if="rateType === 'CLOSED_BY_USER'">
          Auction closed
        </template>
        <template v-if="rateType === 'TRANSFER'">
          Transferred to
          <UsernameLink
            :username="rateNewOwnerNickname"
            :user-id="rateNewOwnerId"
            :user-address="rateNewOwnerAddress"
          />
        </template>
      </div>

      <div class="activity-desc">
        by
        <UsernameLink
          v-if="rateType !== 'PURCHASE'"
          :user-address="rateOwnerAddress"
          :user-id="rateOwnerId"
          :username="rateOwnerNickname"
        />
        <UsernameLink
          v-else
          :user-address="rateNewOwnerAddress"
          :user-id="rateNewOwnerId"
          :username="rateNewOwnerNickname"
        />
        {{ timestampToCustomString(rateCreated, "long-time") }}
      </div>
    </div>
    <p @click="openLink(rateHash, rateCurrency, 'tx')" class="svg blank">
      <svg><use xlink:href="#svg-blank" /></svg>
    </p>
  </div>
</template>

<script>
import timestampToCustomStringMixin from "@/mixins/timestampToCustomStringMixin";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import rateInfoMixin from "@/mixins/rateInfoMixin";
import UsernameLink from "@/components/common/UsernameLink";
import appointExplorerLinkMixin from "@/mixins/appointExplorerLinkMixin";

export default {
  name: "HistoryItem",
  components: { UsernameLink },
  mixins: [
    timestampToCustomStringMixin,
    formatNumberMixin,
    rateInfoMixin,
    timestampToCustomStringMixin,
    appointExplorerLinkMixin,
  ],
  props: {
    rate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openLink(rateHash, rateCurrency, type) {
      window.open(this.appointExplorerLink(rateHash, rateCurrency, type));
    },
  },
};
</script>
