<template>
  <div class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <div class="popup-content">
        <template v-if="state === 'token'">
          <h3>Burning NFT</h3>
          <p>Are you sure you want to burn this NFT?</p>
        </template>

        <template v-if="state === 'collection'">
          <h3>Deleting Collection</h3>
          <p>Are you sure you want to delete this collection?</p>
        </template>

        <div class="buttons">
          <button type="button" class="button" @click="accept">Yes</button>
          <button type="button" class="button green" @click="close">No</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeModalMixin from "@/mixins/closeModalMixin";

export default {
  name: "DeleteModal",
  mixins: [closeModalMixin],
  props: {
    state: {
      type: String,
      required: true,
      default: () => "token",
    },
  },
  methods: {
    accept() {
      this.$emit("accept");
    },
  },
};
</script>
