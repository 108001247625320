<template>
  <div class="bidding-wrapper">
    <template v-if="tokenBlockChainId">
      <div class="bidding-item" v-if="!isSold">
        <div class="flex" v-if="!isSold && isTimeAuction">
          <div class="bidding-half">
            <div class="bidding-label">Current bid</div>
            <div class="bidding-amount">
              {{ formatNumber(minBidPrice) }} {{ currency }}
            </div>
          </div>
          <div class="bidding-half">
            <Countdown
              :end-time="endTime"
              :start-time="startTime"
              :is-short="false"
            />
          </div>
        </div>

        <div class="bidding-full" v-if="!isSold && !isTimeAuction">
          <div class="bidding-label">Price</div>
          <div class="bidding-amount">
            {{ formatNumber(minBidPrice) }} {{ currency }}
          </div>
        </div>

        <div
          class="bidding-full actions"
          v-if="
            isTimeAuction && !auctionIsNotStarted && !isWinner && !auctionEnd
          "
        >
          <div class="fel">
            <input
              type="number"
              :placeholder="!canMakeControl ? payableAmount : 'Enter new bid'"
              v-model="payableAmount"
              step="0.000000001"
              :disabled="!canMakeControl"
            />
          </div>
          <div class="coin">
            <span>{{ currency }}</span>
          </div>

          <button
            type="button"
            class="button green"
            @click="createRate"
            v-if="canMakeControl || !getIsAuthorized"
          >
            Place a bid
          </button>

          <button
            type="button"
            class="button green"
            @click="setChain({ symbol: currency })"
            v-if="!canMakeControl && getIsAuthorized"
          >
            Change network
          </button>
        </div>

        <div class="bidding-full actions" v-if="!isTimeAuction && !isSold">
          <div class="fel" v-if="isOwner">
            <input
              type="number"
              placeholder="Enter new price"
              v-model="newPrice"
              step="0.00000001"
            />
          </div>
          <div class="coin" v-if="isOwner">
            <span>{{ currency }}</span>
          </div>

          <button
            type="button"
            class="button green"
            @click="changeCostSimpleAuction"
            v-if="isOwner && canMakeControl"
          >
            Change price
          </button>

          <button
            type="button"
            class="button green"
            @click="createRate"
            v-if="canMakeControl || !getIsAuthorized"
          >
            Buy
          </button>

          <button
            type="button"
            class="button green"
            @click="setChain({ symbol: currency })"
            v-if="!canMakeControl && getIsAuthorized"
          >
            Change network
          </button>
        </div>
      </div>

      <div
        class="bidding-item"
        v-if="isOwner && isTimeAuction && !isWinner && !auctionEnd"
      >
        <div class="flex">
          <div class="bidding-half">
            <div class="fel">
              <datetime
                v-model="newStartTime"
                :type="'datetime'"
                :min-datetime="minDateTime"
              />
            </div>
          </div>
          <div class="bidding-half">
            <div class="fel">
              <datetime
                v-model="newEndTime"
                :type="'datetime'"
                :min-datetime="minDateTime"
              />
            </div>
          </div>
        </div>

        <div class="bidding-full actions">
          <button type="button" class="button green" @click="changeTimeAuction">
            Change time
          </button>
        </div>
      </div>

      <div
        class="bidding-item"
        v-if="isOwner && isTimeAuction && !isWinner && !auctionEnd"
      >
        <div class="bidding-full actions">
          <button type="button" class="button" @click="closeAuctionByUser">
            Cancel auction
          </button>
        </div>
      </div>

      <div class="bidding-item" v-if="isOwner && !isTimeAuction && !isSold">
        <div class="bidding-full actions">
          <button type="button" class="button" @click="closeAuctionByUser">
            Cancel auction
          </button>
        </div>
      </div>

      <div class="conclusion" v-if="isWinner">
        <div class="conc-title">You are the winner of&nbsp;the auction</div>
        <div class="conc-buttons">
          <div class="button green" @click="checkPendingAuction">
            End auction
          </div>
        </div>
      </div>

      <div class="conclusion" v-if="isOwnerNoAuction">
        <div class="conc-title">No&nbsp;active auction</div>
        <div>You are the owner of&nbsp;this NFT</div>
        <div class="conc-buttons">
          <div class="button green" @click="sell">Sell token</div>
          <div class="button green" @click="transfer">Transfer token</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="bidding-item">
        <div class="bidding-full">
          <div class="bidding-label">Price</div>
          <div class="bidding-amount">
            {{ formatNumber(tokenMetaPrice) }} {{ currency }}
          </div>
        </div>

        <div class="bidding-full actions">
          <button
            type="button"
            class="button green"
            @click="buyGasLessToken"
            v-if="!isOwner"
          >
            Buy
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import contract from "@/api/contract";
import { mapActions, mapGetters } from "vuex";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import getIsAuthorizedMixin from "@/mixins/getIsAuthorizedMixin";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import Countdown from "@/components/common/Countdown";
import rateInfoMixin from "@/mixins/rateInfoMixin";
import checkIsWalletConnectMixin from "@/mixins/checkIsWalletConectMixin";
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";
import errors from "@/util/errors";
import successes from "@/util/successes";
import notices from "@/util/notices";
import api from "../../api/api";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "ControlPanel",
  mixins: [
    setProcessingMixin,
    getIsAuthorizedMixin,
    formatNumberMixin,
    rateInfoMixin,
    checkIsWalletConnectMixin,
    capitalizeFirstLetterMixin,
    useLinkMixin,
  ],
  props: {
    tokenBlockChainId: {
      type: Number,
      required: false,
    },
    tokenId: {
      type: Number,
      required: false,
    },
    isTimeAuction: {
      type: Boolean,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    minBidPrice: {
      type: Number,
      required: true,
    },
    isSold: {
      type: Boolean,
      required: true,
    },
    auctionId: {
      type: Number,
      required: true,
    },
    ownerAddress: {
      type: String,
    },
    tokenMetaPrice: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data: () => ({
    newStartTime: new Date(Date.now()).toISOString(),
    newEndTime: new Date(Date.now()).toISOString(),
    minDateTime: new Date(Date.now()).toISOString(),
    payableAmount: 0,
    canCancelAuction: true,
    newPrice: null,
  }),
  computed: {
    ...mapGetters({
      isWrongChainId: "wallet/isWrongChainId",
      getMultiplier: "wallet/getMultiplier",
      getAddress: "user/getAddress",
      getSymbol: "wallet/getSymbol",
      getNetwork: "wallet/getNetwork",
      getAuction: "auction/getAuction",
      getMarketContract: "wallet/getMarketContract",
      getForwarderContract: "wallet/getForwarderContract",
      getContract: "wallet/getContract",
    }),
    auctionIsNotStarted() {
      return !!(
        this.startTime && this.startTime > Math.floor(Date.now() / 1000)
      );
    },
    canMakeControl() {
      return !this.isWrongChainId && this.getSymbol === this.currency;
    },
    isWinner() {
      return (
        this.isOwner &&
        !this.isSold &&
        this.isTimeAuction &&
        Date.now() / 1000 > this.endTime
      );
    },
    auctionEnd() {
      if (!this.isSold) {
        return Date.now() / 1000 > this.endTime;
      } else {
        return true;
      }
    },
    isOwner() {
      return this.ownerAddress === this.getAddress;
    },
    isOwnerNoAuction() {
      return this.isSold && this.isOwner;
    },
  },
  watch: {
    canMakeControl() {
      this.initialize();
    },
  },
  methods: {
    ...mapActions({
      setChain: "wallet/setChain",
    }),
    async buyGasLessToken() {
      try {
        this.setLoading(true);
        const buyTokenABI = ["function buyTokenLowGas()"];
        const buyTokenSelector = "buyTokenLowGas";
        const buyTokenData = contract.encodeFunctionCall(
          [],
          buyTokenABI,
          buyTokenSelector
        );
        const metaTransactionRequest = {
          from: this.getAddress.toLowerCase(), // buyer address
          to: this.getMarketContract.toLowerCase(),
          value: contract.formatPrice(this.tokenMetaPrice), // price of token
          data: buyTokenData,
        };

        const domain = contract.generateDomainData(
          "Forwarder",
          this.getForwarderContract.toLowerCase()
        );

        const msgParams = JSON.stringify({
          types: {
            ForwardRequest: [
              { name: "from", type: "address" },
              { name: "to", type: "address" },
              { name: "value", type: "uint256" },
              { name: "data", type: "bytes" },
            ],
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              { name: "verifyingContract", type: "address" },
            ],
          },
          domain: {
            name: domain.name,
            version: domain.version,
            chainId: domain.chainId,
            verifyingContract: domain.verifyingContract.toLowerCase(),
          },
          primaryType: "ForwardRequest",
          message: metaTransactionRequest,
        });

        let params = [this.getAddress, msgParams];

        const signature = await contract.signTypedDataV4(params);

        let sellerData = await api.getMetaTransactionInfo({
          innerId: this.tokenId,
        });
        const sellerSig = sellerData.signature;
        delete sellerData.signature;
        const response = await contract.executeForMarket(
          sellerData,
          sellerSig,
          metaTransactionRequest,
          signature,
          this.tokenMetaPrice
        );

        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setLoading(false);
          return;
        }
        this.setSuccess(successes.SUCCESS_PURCHASE);
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
    async sell() {
      if (!(await this.validate())) {
        return;
      }
      this.$emit("sell");
    },
    async transfer() {
      if (!(await this.validate())) {
        return;
      }
      this.$emit("transfer");
    },
    async validate(operation) {
      if (!this.getIsAuthorized) {
        this.setError(errors.NEED_LOGIN);
        return false;
      }
      if (this.currency !== this.getSymbol) {
        this.setError(
          `${this.$t(
            errors.TOKEN_DIFFERENT_NETWORK_FIRST
          )} ${this.capitalizeFirstLetter(this.getNetwork)} ${this.$t(
            errors.TOKEN_DIFFERENT_NETWORK_SECOND
          )} ${this.getSymbol === "ETH" ? "Polygon" : "Ethereum"}. ${this.$t(
            errors.TOKEN_DIFFERENT_NETWORK_THIRD
          )}`
        );
        await this.setChain({ symbol: this.currency });
        this.setError(null);
        return false;
      }
      if (operation === "burn" && !this.isSold) {
        this.setError(errors.CANT_BURN_TOKEN);
        return false;
      }
      if (operation === "closeAuctionByUser") {
        if (!this.canCancelAuction) {
          this.setError(errors.AUCTION_HAS_ACTIVE_BIDS);
          return false;
        }
      }
      if (operation === "changeTimeAuction") {
        if (this.newStartTime < Math.floor(Date.now() / 1000)) {
          this.setError(errors.START_TIME_LESS_THAN_CURRENT);
          return false;
        }
        if (this.newEndTime < Math.floor(Date.now() / 1000)) {
          this.setError(errors.END_TIME_LESS_THAN_CURRENT);
          return false;
        }
        if (this.newEndTime <= this.newStartTime) {
          this.setError(errors.END_TIME_LESS_THAN_START);
          return false;
        }
      }
      return true;
    },
    async changeTimeAuction() {
      if (!(await this.validate("changeTimeAuction"))) {
        return;
      }
      try {
        this.setLoading(true);
        const response = await contract.changeTimeAuction(
          this.tokenBlockChainId,
          Math.floor(Date.parse(this.newStartTime) / 1000),
          Math.floor(Date.parse(this.newEndTime) / 1000)
        );
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setLoading(false);
          return;
        }
        this.setSuccess(successes.AUCTION_TIME_FRAME_CHANGED);
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
    async changeCostSimpleAuction() {
      if (!(await this.validate())) {
        return;
      }
      try {
        this.setLoading(true);
        const response = await contract.changeCostSimpleAuction(
          this.auctionId,
          this.newPrice
        );
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setLoading(false);
          return;
        }
        this.setSuccess(successes.AUCTION_PRICE_CHANGED);
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
    async burn() {
      if (!(await this.validate("burn"))) {
        return;
      }
      try {
        this.setLoading(true);
        if (this.tokenBlockChainId) {
          const response = await contract.burn(this.tokenBlockChainId);
          if (this.checkIsWalletConnect(response)) {
            this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
            this.setLoading(false);
            return;
          }
        } else {
          await api.deleteMetaToken({
            tokenId: this.tokenId,
          });
        }
        this.setSuccess(successes.TOKEN_BURNED);
        this.setLoading(false);
        await this.$router.push(this.homeLink());
      } catch (e) {
        this.setError(e.message);
        this.setLoading(false);
      }
    },
    async closeAuctionByUser() {
      if (!(await this.validate("closeAuctionByUser"))) {
        return;
      }
      try {
        this.setLoading(true);
        const response = await contract.closeAuctionByUser(this.auctionId);
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setLoading(false);
          return;
        }
        this.setSuccess(successes.AUCTION_CLOSED);
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
    async checkPendingAuction() {
      if (!(await this.validate())) {
        return;
      }
      try {
        this.setLoading(true);
        const response = await contract.checkPendingAuction(this.auctionId);
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setLoading(false);
          return;
        }
        this.setSuccess(successes.AUCTION_CLOSED);
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
    async createRate() {
      if (!(await this.validate())) {
        return;
      }
      try {
        this.setLoading(true);
        let response = null;
        if (this.isTimeAuction) {
          this.setNotice(notices.CREATING_BID);
          response = await contract.createRateByTimeAuction(
            this.payableAmount,
            this.auctionId
          );
        } else {
          this.setNotice(notices.PURCHASE_PROCESS);
          response = await contract.buyTokenBySimpleAuction(
            this.auctionId,
            this.payableAmount
          );
        }
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
          this.setNotice(null);
          this.setLoading(false);
          return;
        }
        switch (!this.isTimeAuction) {
          case true:
            this.setSuccess(successes.SUCCESS_PURCHASE);
            break;
          case false:
            this.setSuccess(successes.BID_PLACED);
            break;
          default:
            this.setError("Wrong auction type");
        }
        this.initialize();
        this.$emit("success");
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setNotice(null);
        this.setLoading(false);
      }
    },
    async getMinBidFromAuction() {
      try {
        const response = await contract.getMinBidFromAuction(this.auctionId);
        this.payableAmount = this.formatNumber(response / this.getMultiplier);
      } catch (e) {
        this.setError(e.message);
      }
    },
    async rateOfAuctionId() {
      try {
        const response = await contract.rateOfAuctionId(this.auctionId);
        if (response !== "0") {
          this.canCancelAuction = false;
        }
      } catch (e) {
        this.setError(e.message);
      }
    },
    initialize() {
      if (this.canMakeControl) {
        if (this.isTimeAuction && !this.isSold) {
          this.getMinBidFromAuction();
          this.rateOfAuctionId();
        }
        if (!this.isTimeAuction) {
          this.payableAmount = this.minBidPrice;
        }
      } else {
        if (this.isTimeAuction && !this.isSold) {
          this.payableAmount = this.$t(errors.WRONG_CHAIN);
        }
      }
    },
  },
  mounted() {
    this.initialize();
  },
  components: {
    Countdown,
  },
};
</script>
