<template>
  <div class="tabs-container">
    <div class="tabs">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ active: item === tab }"
        @click="toggleTab(item)"
      >
        {{ capitalizeFirstLetter(item) }}
      </div>
    </div>

    <div class="sheet">
      <div v-if="tab === 'bids'">
        <BidItem v-for="(item, index) in items" :rate="item" :key="index" />
      </div>

      <div v-if="tab === 'history'">
        <HistoryItem v-for="(item, index) in items" :rate="item" :key="index" />
      </div>

      <NoRecordsFound v-if="!items.length && !loading" />

      <ScrollLoader
        v-if="getLoaderMethod"
        :loader-method="getLoaderMethod"
        :loader-disable="disableLoading"
        ><img width="200" height="200" src="@/assets/img/preloader.svg" />
      </ScrollLoader>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import rateInfoMixin from "@/mixins/rateInfoMixin";
import BidItem from "@/components/artworkPage/bids/BidItem";
import HistoryItem from "@/components/artworkPage/bids/HistoryItem";
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Bids",
  components: { NoRecordsFound, HistoryItem, BidItem },
  mixins: [rateInfoMixin, capitalizeFirstLetterMixin, resetPrevLoadingMixin],
  props: {
    blockChainId: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    showBidsTab: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tabs: [],
    tab: null,
    page: 0,
    size: 10,
    loading: false,
    disableLoading: false,
    items: [],
  }),
  computed: {
    ...mapGetters({
      getBid: "rate/getBid",
      getHistory: "rate/getHistory",
    }),
    getLoaderMethod() {
      switch (this.tab) {
        case "bids":
          return this.getNftTokenBids;
        case "history":
          return this.getNftTokenHistory;
      }
      return null;
    },
  },
  watch: {
    getBid(val) {
      if (val && this.tab === "bids") {
        let isRateExists = !!this.items.find((i) => i.rateId === val.rateId);
        if (isRateExists) {
          return;
        }
        this.items.unshift(val);
      }
    },
    getHistory(val) {
      if (val && this.tab === "history") {
        // let isRateExists = !!this.items.find((i) => i.id === val.id);
        // if (isRateExists) {
        //   return;
        // }
        this.items.unshift(val);
      }
    },
  },
  methods: {
    ...mapMutations({
      setBid: "rate/setBid",
      setHistory: "rate/setHistory",
    }),
    toggleTab(tab) {
      this.tab = tab;
      this.resetPrevLoading();
    },
    async getNftTokenBids() {
      if (this.page === 0) {
        this.items = [];
      }
      try {
        this.loading = true;
        const response = await api.getNftTokenBids({
          page: this.page++,
          size: this.size,
          blockChainId: this.blockChainId,
          currency: this.currency,
          sort: "created,desc",
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
    async getNftTokenHistory() {
      if (this.page === 0) {
        this.items = [];
      }
      try {
        this.loading = true;
        const response = await api.getNftTokenHistory({
          page: this.page++,
          size: this.size,
          blockChainId: this.blockChainId,
          currency: this.currency,
          sort: "created,desc",
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (!this.showBidsTab) {
      this.tabs = ["history"];
    } else {
      this.tabs = ["bids", "history"];
    }
    this.tab = this.tabs[0];
  },
};
</script>
