<template>
  <div class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <div class="popup-content">
        <h3 v-if="!isSimpleAuction">Put on auction</h3>
        <h3 v-else>Put up for sale</h3>

        <div class="form">
          <div class="fel">
            <div class="fel-label">Type of sale</div>
            <div class="radio radio-buttons">
              <label v-for="(item, index) in auctionTypes" :key="index">
                <input
                  type="radio"
                  name="auction"
                  :value="item.value"
                  v-model="auctionType"
                />
                <span class="cell"></span>
                <span class="text">{{ item.text }}</span>
              </label>
            </div>
          </div>

          <div class="fel" v-if="!isSimpleAuction">
            <div class="fel-label">Starting date</div>
            <datetime
              v-model="startTime"
              :type="'datetime'"
              :min-datetime="minDateTime"
            />
          </div>

          <div class="fel" v-if="!isSimpleAuction">
            <div class="fel-label">Expiration date</div>
            <datetime
              v-model="endTime"
              :type="'datetime'"
              :min-datetime="minDateTime"
            />
          </div>

          <div class="fel">
            <div class="fel-label">Price</div>
            <div class="fel-price">
              <input
                type="number"
                placeholder="0"
                step="0.00000001"
                v-model="minBidPrice"
              />
              <div class="coin">{{ currency }}</div>
            </div>
            <div class="fel-error"></div>
            <div class="fel-eq">Equivalent 0.00 USD</div>
          </div>

          <button type="button" class="button green" @click="createAuction">
            Put on auction
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auctionConfigMixin from "@/mixins/auctionConfigMixin";
import closeModalMixin from "@/mixins/closeModalMixin";
import contract from "@/api/contract";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import checkIsWalletConectMixin from "@/mixins/checkIsWalletConectMixin";
import errors from "@/util/errors";
import successes from "@/util/successes";

export default {
  name: "ListingModal",
  mixins: [
    auctionConfigMixin,
    closeModalMixin,
    setProcessingMixin,
    checkIsWalletConectMixin,
  ],
  props: {
    tokenId: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    minBidPrice: null,
  }),
  methods: {
    async validate() {
      if (!this.minBidPrice) {
        this.setError(errors.MIN_BID_REQUIRED);
        return false;
      }
      const gasPrice = await contract.getGasPrice();
      if (gasPrice >= this.minBidPrice) {
        this.setNeutral(errors.GAS_PRICE_WARNING);
        return false;
      }
      return !(!this.isSimpleAuction && !this.timeAuctionValidation());
    },
    async createAuction() {
      try {
        if (!(await this.validate())) {
          return;
        }
        this.setLoading(true);
        let response = null;
        switch (this.auctionType) {
          case "fixed":
            response = await contract.createSimpleAuction(
              this.tokenId,
              this.minBidPrice
            );
            break;
          case "live":
            response = await contract.createTimeAuction(
              this.tokenId,
              this.startTimeComputed,
              this.endTimeComputed,
              this.minBidPrice
            );
            break;
        }
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(successes.TRANSACTION_REQUEST_SENT);
        } else {
          this.setSuccess(successes.UP_FOR_SALE);
        }
        this.$emit("success");
        this.close();
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
