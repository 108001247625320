export default {
  computed: {
    rateType() {
      return this.rate.type;
    },
    rateOwnerNickname() {
      return this.rate.owner.nickname;
    },
    rateOwnerAddress() {
      return this.rate.owner.address;
    },
    rateOwnerId() {
      return this.rate.owner.id;
    },
    ratePrice() {
      return this.rate.price;
    },
    rateCurrency() {
      return this.rate.currency;
    },
    rateCreated() {
      return this.rate.created;
    },
    rateHash() {
      return this.rate.hash;
    },
    rateNewOwnerNickname() {
      return this.rate.newOwner?.nickname || "";
    },
    rateNewOwnerAddress() {
      return this.rate.newOwner?.address || "";
    },
    rateNewOwnerId() {
      return this.rate.newOwner?.id || "";
    },
  },
};
