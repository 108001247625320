<template>
  <div class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <div class="popup-content">
        <h3 class="center">Transfer NFT</h3>

        <div class="form">
          <div class="fel">
            <input
              type="text"
              name="address"
              placeholder="Address"
              v-model="to"
            />
            <div class="fel-error"></div>
          </div>

          <button type="button" class="button green" :disabled="!to" @click="transferFrom">Transfer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeModalMixin from "@/mixins/closeModalMixin";
import contract from "@/api/contract";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import checkIsWalletConectMixin from "@/mixins/checkIsWalletConectMixin";

export default {
  name: "TransferModal",
  mixins: [closeModalMixin, setProcessingMixin, checkIsWalletConectMixin],
  props: {
    tokenId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    to: null,
  }),
  methods: {
    async transferFrom() {
      try {
        this.setLoading(true);
        const response = await contract.transferFrom(this.to, this.tokenId);
        if (this.checkIsWalletConnect(response)) {
          this.setSuccess(
            "Your request has been sent, wait for the transaction in the blockchain network to complete. This may take time."
          );
        } else {
          this.setSuccess("The token has been transferred");
        }
        this.$emit("success");
        this.close();
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
