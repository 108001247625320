<template>
  <div class="activity-item">
    <div class="activity-info">
      <div class="activity-desc">
        Bid placed by
        <UsernameLink
          :username="rateOwnerNickname"
          :user-id="rateOwnerId"
          :user-address="rateOwnerAddress"
        />
        for
        <span>{{ formatNumber(ratePrice) }} {{ rateCurrency }}</span>
      </div>
      <div class="activity-desc">{{ timestampToCustomString(rateCreated, "long-time") }}</div>
    </div>
    <a :href="appointExplorerLink(rateHash, rateCurrency, 'tx')" target="_blank" class="svg blank">
      <svg><use xlink:href="#svg-blank" /></svg>
    </a>
  </div>
</template>

<script>
import UsernameLink from "@/components/common/UsernameLink";
import timestampToCustomStringMixin from "@/mixins/timestampToCustomStringMixin";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import rateInfoMixin from "@/mixins/rateInfoMixin";
import appointExplorerLinkMixin from "@/mixins/appointExplorerLinkMixin";
export default {
  name: "BidItem",
  mixins: [
    timestampToCustomStringMixin,
    formatNumberMixin,
    rateInfoMixin,
    appointExplorerLinkMixin,
  ],
  components: { UsernameLink },
  props: {
    rate: {
      type: Object,
      required: true,
    },
  },
};
</script>
